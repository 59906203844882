import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js/auto'
import 'chartjs-adapter-date-fns'
import annotationPlugin from 'chartjs-plugin-annotation'
Chart.register(annotationPlugin)

export default class extends Controller {
  static values = {
    data: Array,
    windowStart: {
      type: String,
      default: '',
    },
    windowEnd: {
      type: String,
      default: '',
    },
    windowShow: Boolean,
  }

  connect() {
    var dataPoints = this.dataValue

    this.chart = new Chart(this.element, {
      type: 'line',
      data: {
        datasets: [
          {
            data: dataPoints,
            tension: 0.3,
            pointRadius: 6,
            pointBackgroundColor: (ctx) => pointColor(ctx.raw.inProgress),
            pointBorderColor: '#FFFFFF',
            pointBorderWidth: 2,
            pointHoverRadius: 9,
            pointHoverBorderWidth: 3,
            pointHoverBorderColor: '#FFFFFF',
            pointHoverBackgroundColor: (ctx) => pointColor(ctx.raw.inProgress),
            segment: {
              borderDash: (ctx) =>
                ctx.p1.raw.inProgress ? [10, 10] : undefined,
              borderColor: (ctx) => pointColor(ctx.p1.raw.inProgress),
            },
          },
        ],
      },
      options: {
        aspectRatio: 16 / 9,
        animation: {
          duration: 0,
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
            },
            border: {
              display: false,
            },
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              padding: 8,
            },
          },
          y: {
            border: {
              display: false,
            },
            grid: {
              drawTicks: false,
            },
            ticks: {
              padding: 8,
            },
            grace: '1',
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            annotations: {
              window: {
                type: 'box',
                adjustScaleRange: false,
                display: this.windowShowValue,
                xMin: this.windowStartValue,
                xMax: this.windowEndValue,
                backgroundColor: 'rgba(248, 248, 248, 1)',
                drawTime: 'beforeDraw',
                borderRadius: 8,
                borderColor: 'transparent',
              },
            },
          },
          tooltip: {
            enabled: false,
            external: externalTooltipHandler,
          },
        },
      },
      plugins: [
        {
          id: 'mouseline',
          afterInit: (chart, args, opts) => {
            chart.mouseline = {}
          },
          afterEvent: (chart, args, options) => {
            const { inChartArea } = args
            chart.mouseline = { draw: inChartArea }
          },
          beforeTooltipDraw: (chart, args, options) => {
            const { draw } = chart.mouseline
            if (!draw) return

            const { ctx } = chart
            const { top, bottom } = chart.chartArea
            const { tooltip } = args
            const x = tooltip?.caretX
            if (!x) return

            ctx.save()

            ctx.beginPath()
            ctx.moveTo(x, top)
            ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)'
            ctx.lineTo(x, bottom)
            ctx.stroke()

            ctx.restore()
          },
        },
        {
          id: 'customCanvasBackgroundColor',
          beforeDraw: (chart, args, options) => {
            const { ctx } = chart
            ctx.save()
            ctx.globalCompositeOperation = 'destination-over'
            ctx.fillStyle = '#fff'
            ctx.fillRect(0, 0, chart.width, chart.height)
            ctx.restore()
          },
        },
      ],
    })
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy()
    }
  }
}

const pointColor = (inProgress) => (inProgress ? '#B7A5FB' : '#8467F3')

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.background = '#222'
    tooltipEl.style.borderRadius = '8px'
    tooltipEl.style.color = 'white'
    tooltipEl.style.opacity = 1
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transition = 'all .1s ease'
    tooltipEl.style.zIndex = 10
    tooltipEl.style.margin = '12px'
    tooltipEl.style.padding = '6px 8px'

    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  } else {
    tooltipEl.style.opacity = 1
  }

  const dataPoint = tooltip.dataPoints[0].raw

  // Set Text
  tooltipEl.innerHTML = `
    <div class="flex flex-col gap-2">
      <div class="flex flex-col gap-1">
        <span class="text-sm text-white font-medium">${dataPoint.date}</span>
        <span class="${
          dataPoint.inProgress ? '' : 'hidden'
        } text-xs whitespace-nowrap">
          <span class="text-[#FACC15] px-1 py-0.5 bg-[#FACC15]/30 rounded">30%+ of plans are still active</span>
        </span>
      </div>
      <div class="flex flex-col gap-1">
        <span class="text-xs text-white/60 whitespace-nowrap">
          <span class="text-white px-1 py-0.5 bg-white/20 rounded">~${
            dataPoint.y
          } days</span> avg duration
        </span>
        <span class="text-xs text-white/60 whitespace-nowrap">
          <span class="text-white px-1 py-0.5 bg-white/20 rounded">${
            dataPoint.active
          }</span> plans active
        </span>
        <span class="text-xs text-white/60 whitespace-nowrap">
          <span class="text-white px-1 py-0.5 bg-white/20 rounded">${
            dataPoint.completed
          }</span> plans completed
        </span>
      </div>
    </div>
  `

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  if (tooltip.caretX >= chart.width - 150) {
    tooltipEl.style.left =
      positionX + tooltip.caretX - tooltipEl.clientWidth + 'px'
  } else {
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
  }

  tooltipEl.style.top = positionY + tooltip.caretY + 'px'
}
