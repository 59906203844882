import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['editor']
  static values = {
    preventFileUpload: Boolean,
    maxFileSize: Number,
    editorMinHeight: String,
  }

  connect() {
    this.trixCallback = this.onTrixInitialize.bind(this)
    this.editorTarget.addEventListener('trix-initialize', this.trixCallback)
  }

  disconnect() {
    this.editorTarget.removeEventListener('trix-initialize', this.trixCallback)
  }

  onTrixInitialize() {
    if (this.hasEditorMinHeightValue) {
      this.editorTarget.style.minHeight = this.editorMinHeightValue
    }
  }

  onFileAccept(event) {
    if (this.preventFileUploadValue) {
      event.preventDefault()
      alert('Attaching files or images is not allowed.')
      return
    }

    if (event.file.size > this.maxFileSizeValue) {
      event.preventDefault()
      alert('This file is too large: max file size is 10 MB')
    }
  }
}
