import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'
import template from 'lodash/template'

export default class extends Controller {
  static targets = ['itemTemplate', 'selectedTemplate']

  connect() {
    this.selectedTemplate = template(this.selectedTemplateTarget.innerHTML)
    this.itemTemplate = template(this.itemTemplateTarget.innerHTML)

    const settings = {
      plugins: ['dropdown_input', 'no_backspace_delete'],
      searchField: [
        { field: 'text', weight: 10 },
        { field: 'description', weight: 5 },
      ],
      render: {
        option: (data) => this.selectedTemplate({ data }),
        item: (data) => this.itemTemplate({ data }),
      },
    }

    this.select = new TomSelect(this.element, settings)
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }
}
