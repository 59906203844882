import { Controller } from '@hotwired/stimulus'
import { enter } from 'el-transition'

export default class extends Controller {
  static targets = ['overlay']

  toggle() {
    if (this.element.hasAttribute('open')) {
      // not worth using leave here, as the toggle event is triggered *after* the details element is closed
      enter(this.overlayTarget).then(this.scrollIntoView.bind(this))
    } else {
      this.overlayTarget.classList.add('hidden')
    }
  }

  scrollIntoView() {
    this.overlayTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
