import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    moveFocusToElementId: String,
    spinnerElementId: String,
  }

  connect() {
    this.trixCallback = this.onTrixInitialize.bind(this)
    this.element.addEventListener('trix-initialize', this.trixCallback)
  }

  disconnect() {
    this.element.removeEventListener('trix-initialize', this.trixCallback)
  }

  onTrixInitialize() {
    this.element.addEventListener('keydown', async (event) => {
      if (event.key === 'Enter' && event.shiftKey === false) {
        event.preventDefault()

        const element = document.getElementById(this.moveFocusToElementIdValue)
        const spinner = document.getElementById(this.spinnerElementIdValue)
        element.focus()
        spinner.classList.remove('hidden')

        const formUrl = this.element.form.action
        const formData = new FormData(this.element.form)
        try {
          const response = await post(formUrl, { body: formData })
          if (response.ok) {
            element.click()
          } else {
            spinner.classList.add('hidden')
            event.target.focus()
            alert(
              'An error occurred while saving the checklist item. Please try again.'
            )
          }
        } catch (error) {
          spinner.classList.add('hidden')
          event.target.focus()
          alert(
            'An error occurred while saving the checklist item. Please try again.'
          )
        }
      }
    })
  }
}
