import { StreamActions } from '@hotwired/turbo'

function isVisible(element) {
  return element.offsetParent !== null
}

StreamActions.hard_refresh_frame = function () {
  this.targetElements.forEach(async (element) => {
    element.src = window.location.href
    await element.loaded
    element.removeAttribute('src')
  })
}

StreamActions.click_on = function () {
  const element = document.querySelector(this.getAttribute('selector'))

  if (element && isVisible(element)) {
    element.click()
  }
}
