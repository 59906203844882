import { Controller } from '@hotwired/stimulus'

import tippy from 'tippy.js'

export default class extends Controller {
  connect() {
    this.createTippy()
  }

  async createTippy() {
    this.tippyInstance = tippy(this.element, {
      duration: 75,
      delay: [250, 100],
      allowHTML: true,
    })
  }

  disconnect() {
    this.destroyTippy()
  }

  async destroyTippy() {
    if (this.tippyInstance) {
      this.tippyInstance.destroy()
    }
  }
}
