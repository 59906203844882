// Entry point for the build script in your package.json

import './helpers/sentry'
import 'trix'
import '@rails/actiontext'
import * as ActiveStorage from '@rails/activestorage'
import Rails from '@rails/ujs'
import '@github/relative-time-element'
import 'chartkick/chart.js'

import './controllers'

ActiveStorage.start()

Trix.config.attachments.preview.caption.size = false
Trix.config.attachments.preview.caption.name = false
Trix.config.attachments.file.caption.size = false

import './data_confirm_override'
import './custom_turbo_stream_actions'

// We should only start Rails UJS if it hasn't been started yet. Otherwise this will raise
// and other things may not work.
if (!window._rails_loaded) {
  Rails.start()
}
