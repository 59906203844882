import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (document.location.hash) {
      const anchor = document.location.hash.slice(1)
      const block = document.querySelector(`[id="rooms_block_${anchor}"]`)
      if (block) {
        block.appendChild(this.animationTarget())

        this.addDataAttributeValue(block, 'controller', 'animations--pulse')
        // Stop the pulse animation only 2s after hovering the block
        // to avoid the animation being interrupted immediately when
        // a mouseover is fired because the act of scrolling to the block
        // triggers a mouseover event (because of the position of the mouse
        // at the moment of the page being loaded).
        this.addDataAttributeValue(block, 'animations--pulse-delay-param', 2000)
        this.addDataAttributeValue(
          block,
          'action',
          'mouseover->animations--pulse#stopIn'
        )

        block.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }

  animationTarget() {
    const div = document.createElement('div')
    div.setAttribute('data-animations--pulse-target', 'item')
    div.classList.add(
      'ring-4',
      'ring-offset-1',
      'ring-neutral-600',
      'rounded-3xl',
      'absolute',
      'inset-0',
      'opacity-0',
      'pointer-events-none'
    )
    return div
  }

  addDataAttributeValue(element, attribute, value) {
    const current_value = element.dataset[attribute] || ''
    element.setAttribute(`data-${attribute}`, current_value + ' ' + value)
  }
}
