import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { directUpload: Boolean }
  static targets = [
    'inputContainer',
    'displayContainer',
    'input',
    'filename',
    'progress',
    'deleteButton',
  ]

  connect() {
    if (this.directUploadValue) {
      this.inputTarget.form.addEventListener(
        'direct-uploads:start',
        this.onUploadStart.bind(this)
      )
      this.inputTarget.form.addEventListener(
        'direct-uploads:end',
        this.onUploadEnd.bind(this)
      )

      this.inputTarget.addEventListener(
        'direct-upload:progress',
        this.onUploadProgress.bind(this)
      )
    }
  }

  onUploadStart(event) {
    const submit = event.target.querySelector('button[type="submit"]')
    submit.disabled = true

    this.deleteButtonTarget.classList.remove('flex')
    this.deleteButtonTarget.classList.add('hidden')
    this.progressTarget.classList.remove('hidden')
  }

  onUploadEnd(event) {
    const submit = event.target.querySelector('button[type="submit"]')
    submit.disabled = false

    this.deleteButtonTarget.classList.add('flex')
    this.deleteButtonTarget.classList.remove('hidden')
    this.progressTarget.classList.add('hidden')
  }

  onUploadProgress(event) {
    this.progressTarget.textContent = `Uploading (${Math.max(
      1,
      Math.trunc(event.detail.progress)
    )}%)...`
  }

  onFileChanged(_event) {
    this.filenameTarget.innerText = this.inputTarget.files[0].name
    this.showDisplay()
  }

  onRemoveFile(_event) {
    this.showInput()
  }

  showInput() {
    this.displayContainerTarget.classList.remove('flex')
    this.displayContainerTarget.classList.add('hidden')
    this.inputContainerTarget.classList.remove('hidden')
    this.inputContainerTarget.classList.add('flex')
  }

  showDisplay() {
    this.displayContainerTarget.classList.remove('hidden')
    this.displayContainerTarget.classList.add('flex')
    this.inputContainerTarget.classList.remove('flex')
    this.inputContainerTarget.classList.add('hidden')
  }
}
