// ES6 port of https://github.com/RoBYCoNTe/js-bot-detector
export default class BotDetector {
  constructor(args) {
    this.isBot = false
    this.tests = {}
    const selectedTests = args.tests || []

    if (
      selectedTests.length === 0 ||
      selectedTests.includes(BotDetector.Tests.SCROLL)
    ) {
      this.tests[BotDetector.Tests.SCROLL] = () => {
        const e = () => {
          this.tests[BotDetector.Tests.SCROLL] = true
          this.update()
          this.unbindEvent(window, BotDetector.Tests.SCROLL, e)
          this.unbindEvent(document, BotDetector.Tests.SCROLL, e)
        }
        this.bindEvent(window, BotDetector.Tests.SCROLL, e)
        this.bindEvent(document, BotDetector.Tests.SCROLL, e)
      }
    }

    if (
      selectedTests.length === 0 ||
      selectedTests.includes(BotDetector.Tests.MOUSE)
    ) {
      this.tests[BotDetector.Tests.MOUSE] = () => {
        const e = () => {
          this.tests[BotDetector.Tests.MOUSE] = true
          this.update()
          this.unbindEvent(window, BotDetector.Tests.MOUSE, e)
        }
        this.bindEvent(window, BotDetector.Tests.MOUSE, e)
      }
    }

    if (
      selectedTests.length === 0 ||
      selectedTests.includes(BotDetector.Tests.KEYUP)
    ) {
      this.tests[BotDetector.Tests.KEYUP] = () => {
        const e = () => {
          this.tests[BotDetector.Tests.KEYUP] = true
          this.update()
          this.unbindEvent(window, BotDetector.Tests.KEYUP, e)
        }
        this.bindEvent(window, BotDetector.Tests.KEYUP, e)
      }
    }

    if (
      selectedTests.length === 0 ||
      selectedTests.includes(BotDetector.Tests.SWIPE)
    ) {
      this.tests[BotDetector.Tests.SWIPE_TOUCHSTART] = () => {
        const e = () => {
          this.tests[BotDetector.Tests.SWIPE_TOUCHSTART] = true
          this.update()
          this.unbindEvent(document, BotDetector.Tests.SWIPE_TOUCHSTART, e)
        }
        this.bindEvent(document, BotDetector.Tests.SWIPE_TOUCHSTART)
      }
    }

    if (
      selectedTests.length === 0 ||
      selectedTests.includes(BotDetector.Tests.DEVICE_MOTION)
    ) {
      this.tests[BotDetector.Tests.DEVICE_MOTION] = () => {
        const e = (event) => {
          if (
            event.rotationRate.alpha ||
            event.rotationRate.beta ||
            event.rotationRate.gamma
          ) {
            const userAgent = navigator.userAgent.toLowerCase()
            const isAndroid = userAgent.includes('android')
            const beta = isAndroid
              ? event.rotationRate.beta
              : Math.round(event.rotationRate.beta / 10) * 10
            const gamma = isAndroid
              ? event.rotationRate.gamma
              : Math.round(event.rotationRate.gamma / 10) * 10

            if (!this.lastRotationData) {
              this.lastRotationData = {
                beta,
                gamma,
              }
            } else {
              let movement =
                beta !== this.lastRotationData.beta ||
                gamma !== this.lastRotationData.gamma

              if (isAndroid) {
                movement = movement && (beta > 0.2 || gamma > 0.2)
              }

              this.tests[BotDetector.Tests.DEVICE_MOTION] = movement
              this.update()

              if (movement) {
                this.unbindEvent(window, BotDetector.Tests.DEVICE_MOTION, e)
              }
            }
          } else {
            this.tests[BotDetector.Tests.DEVICE_MOTION] = false
          }
        }

        this.bindEvent(window, BotDetector.Tests.DEVICE_MOTION, e)
      }
    }

    if (
      selectedTests.length === 0 ||
      selectedTests.includes(BotDetector.Tests.DEVICE_ORIENTATION)
    ) {
      this.tests[BotDetector.Tests.DEVICE_ORIENTATION] = () => {
        const e = () => {
          this.tests[BotDetector.Tests.DEVICE_ORIENTATION] = true
          this.update()
          this.unbindEvent(window, BotDetector.Tests.DEVICE_ORIENTATION, e)
        }
        this.bindEvent(window, BotDetector.Tests.DEVICE_ORIENTATION)
      }
    }

    if (
      selectedTests.length === 0 ||
      selectedTests.includes(BotDetector.Tests.DEVICE_ORIENTATION_MOZ)
    ) {
      this.tests[BotDetector.Tests.DEVICE_ORIENTATION_MOZ] = () => {
        const e = () => {
          this.tests[BotDetector.Tests.DEVICE_ORIENTATION_MOZ] = true
          this.update()
          this.unbindEvent(window, BotDetector.Tests.DEVICE_ORIENTATION_MOZ, e)
        }
        this.bindEvent(window, BotDetector.Tests.DEVICE_ORIENTATION_MOZ)
      }
    }

    this.cases = {}
    this.timeout = args.timeout || 1000
    this.callback = args.callback || null
    this.detected = false
  }

  static Tests = {
    KEYUP: 'keyup',
    MOUSE: 'mousemove',
    SWIPE: 'swipe',
    SWIPE_TOUCHSTART: 'touchstart',
    SWIPE_TOUCHMOVE: 'touchmove',
    SWIPE_TOUCHEND: 'touchend',
    SCROLL: 'scroll',
    GESTURE: 'gesture',
    GYROSCOPE: 'gyroscope',
    DEVICE_MOTION: 'devicemotion',
    DEVICE_ORIENTATION: 'deviceorientation',
    DEVICE_ORIENTATION_MOZ: 'MozOrientation',
  }

  update(notify = true) {
    let count = 0
    let tests = 0
    for (const i in this.tests) {
      if (Object.prototype.hasOwnProperty.call(this.tests, i)) {
        this.cases[i] = this.tests[i] === true
        if (this.cases[i] === true) {
          count++
        }
      }
      tests++
    }
    this.isBot = count === 0
    this.allMatched = count === tests
    if (notify && typeof this.callback === 'function') {
      this.callback(this)
    }
  }

  bindEvent(e, type, handler) {
    if (e.addEventListener) {
      e.addEventListener(type, handler, false)
    } else if (e.attachEvent) {
      e.attachEvent('on' + type, handler)
    }
  }

  unbindEvent(e, type, handler) {
    if (e.removeEventListener) {
      e.removeEventListener(type, handler, false)
    } else {
      const evtName = 'on' + type
      if (e.detachEvent) {
        if (typeof e[evtName] === 'undefined') {
          e[type] = null
        }
        e.detachEvent(evtName)
      }
    }
  }

  monitor() {
    for (const i in this.tests) {
      if (Object.prototype.hasOwnProperty.call(this.tests, i)) {
        this.tests[i].call()
      }
    }
    this.update(false)
    this.timer = setTimeout(() => {
      this.update(true)
    }, this.timeout)
  }

  dispose() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}
