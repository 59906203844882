import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'
import BotDetector from '../../helpers/bot_detector'

const LOCATION_STORAGE_KEY = 'rooms_approximate_location'

export default class extends Controller {
  static values = {
    url: String,
  }

  async connect() {
    this.lastHumanInteractionAt = null
    this.isHuman = false

    this.#startTrackingHumanInteractions()

    this.bot = new BotDetector({
      callback: this.#onBotDetectionTestRun.bind(this),
    })
    this.bot.monitor()

    this.timer = setInterval(() => this.#ping(), 1000 * 60)
  }

  disconnect() {
    if (this.timer) {
      clearInterval(this.timer)
    }

    this.#stopTrackingHumanInteractions()

    this.bot.dispose()
    this.bot = null
    this.lastHumanInteractionAt = null
  }

  #onBotDetectionTestRun(result) {
    const previousIsHuman = this.isHuman

    this.isHuman = !result.isBot

    if (this.isHuman) {
      this.lastHumanInteractionAt = Date.now()
    }

    if (previousIsHuman !== this.isHuman) {
      this.#ping()
    }
  }

  async #ping() {
    if (
      document.hasFocus() &&
      this.isHuman &&
      this.#lastHumanInteractionInLastFiveMinutes()
    ) {
      const location = await this.#getApproximateLocation()

      const response = await patch(this.urlValue, {
        body: {
          location: location,
        },
      })

      if (response.statusCode === 403) {
        Turbo.visit(window.location.pathname, { action: 'replace' })
      }
    }
  }

  async #getApproximateLocation() {
    const cachedLocation = sessionStorage.getItem(LOCATION_STORAGE_KEY)

    if (cachedLocation) {
      return cachedLocation
    }

    try {
      const response = await fetch('https://ipapi.co/json/')
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      return this.#storeLocation(data)
    } catch (error) {
      console.log('Failed to fetch location:', error)
      return ''
    }
  }

  #storeLocation(data) {
    const location = [data.city, data.region_code, data.country]
      .filter((e) => e)
      .join(', ')

    sessionStorage.setItem(LOCATION_STORAGE_KEY, location)
    return location
  }

  #startTrackingHumanInteractions() {
    this.humanInteractionEvents.forEach((eventName) => {
      document.addEventListener(
        eventName,
        this.#trackLastHumanInteraction.bind(this)
      )
    })
  }

  #stopTrackingHumanInteractions() {
    this.humanInteractionEvents.forEach((eventName) => {
      document.removeEventListener(
        eventName,
        this.#trackLastHumanInteraction.bind(this)
      )
    })
  }

  #trackLastHumanInteraction(_e) {
    this.lastHumanInteractionAt = Date.now()
  }

  #lastHumanInteractionInLastFiveMinutes() {
    const fiveMinutes = 1000 * 60 * 5

    return (
      this.lastHumanInteractionAt != null &&
      Date.now() - this.lastHumanInteractionAt < fiveMinutes
    )
  }

  get humanInteractionEvents() {
    return [
      'keyup',
      'mousemove',
      'scroll',
      'touchstart',
      'touchmove',
      'touchend',
      'gesture',
    ]
  }
}
